<template>
  <v-card class="uploader--wrap">
    <div class="pa-6 uploaded">
      <div class="d-flex flex-column align-center">
        <h2 class="text-center px-6">
          {{
            uploadComplete
              ? $t('patientPortal.files.upload.titleComplete')
              : uploading
              ? $t('patientPortal.files.upload.titlePending')
              : $t('patientPortal.files.upload.title')
          }}
        </h2>

        <h3
          style="font-size: 0.7em; font-weight: normal; max-width: 350px; text-align: center"
          class="pb-4"
          v-if="!uploading"
        >
          {{ $t('patientPortal.files.upload.subtitle') }}
        </h3>
      </div>
      <div
        class="input-container pa-6 mx-2 mx-sm-6"
        :class="{ dropping: isDropping, disabled: dropDisabled, uploading }"
        @dragover.prevent="debounceSetDropping(true)"
        @dragend.prevent="debounceSetDropping(false)"
        @drop.prevent="addFileDrop"
        @dragleave.prevent="debounceSetDropping(false)"
        v-if="!uploading"
      >
        <v-file-input
          v-model="file"
          outlined
          dense
          :disabled="dropDisabled"
          :accept="acceptedFileTypes.join(',')"
          class="fileinput"
          ref="uploader"
          hide-details
          @change="addFile"
          :multiple="maxFiles > 1"
        ></v-file-input>
        <div class="file-upload__instruction">
          <v-icon x-large :color="fileIconColor" v-if="!dropDisabled">
            mdi-file-document-plus-outline
          </v-icon>
          <div v-else>
            <span v-if="maxFiles.length === 1">
              {{ $t('patientPortal.files.upload.maxFiles', { max: maxFiles }) }}
            </span>
            <span v-else>
              {{ $t('patientPortal.files.upload.maxFilesPlural', { max: maxFiles }) }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="fileSizeError.length" class="error-text">
        <div v-for="(name, index) in fileSizeError" :key="index">
          {{ $t('patientPortal.files.upload.maxSize', { max: maxSize / 1000000, name }) }}
        </div>
      </div>
      <div class="pt-4 text-center" style="font-size: 0.7em" v-if="!uploading">
        {{
          $t('registration.registerFileUpload.acceptedTypes', {
            types: acceptedFileTypes.join(', '),
          })
        }}
      </div>
      <v-slide-x-transition>
        <div
          v-if="filesToUpload.length"
          class="d-flex flex-column px-2 px-sm-6"
          :class="uploading ? 'mt-4' : 'mt-7'"
        >
          <h3 v-if="!uploading">
            <span v-if="filesToUpload.length === 1">
              {{ $t('patientPortal.files.upload.pendingFile') }}
            </span>
            <span v-else>
              {{ $t('patientPortal.files.upload.pendingFiles') }}
            </span>
          </h3>
          <v-list-item
            v-for="(file, index) in filesToUpload"
            :key="`pending-file-${index}`"
            style="font-size: 0.8em"
          >
            <v-list-item-icon>
              <v-tooltip top v-if="!uploading">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="error" v-bind="attrs" v-on="on" @click="deleteItem(file)"
                    >mdi-close</v-icon
                  >
                </template>
                <span> {{ $t('patientPortal.files.upload.tooltip.remove') }}</span>
              </v-tooltip>

              <v-tooltip top v-else-if="!!filesSuccess[index]">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="success" v-bind="attrs" v-on="on">mdi-check-circle</v-icon>
                </template>
                <span> {{ $t('patientPortal.files.upload.tooltip.success') }} </span>
              </v-tooltip>
              <v-tooltip top v-else-if="!!filesError[index]">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="error" v-bind="attrs" v-on="on">mdi-alert</v-icon>
                </template>
                <span>
                  {{ $t('patientPortal.files.upload.tooltip.error', { error: filesError[index] }) }}
                </span>
              </v-tooltip>
              <v-progress-circular indeterminate v-else size="24" color="grey darken-1">
              </v-progress-circular>
            </v-list-item-icon>
            {{ file.name }}</v-list-item
          >
        </div>
      </v-slide-x-transition>
    </div>
    <div class="w-100 d-flex justify-center pb-6">
      <v-btn
        rounded
        large
        color="primary"
        class="mx-auto"
        :disabled="!filesToUpload.length"
        :loading="uploading && !uploadComplete"
        @click="onButtonClick"
      >
        <span v-if="!uploadComplete">
          {{
            filesToUpload.length > 1
              ? $t('patientPortal.files.upload.uploadMultiple', { num: filesToUpload.length })
              : $t('patientPortal.files.upload.upload')
          }}
        </span>
        <span v-else>{{ $t('main.close') }}</span>
      </v-btn>
    </div>
  </v-card>
</template>
<style lang="scss" scoped>
.uploader--wrap {
  border-radius: 24px !important;
}
.error-text {
  font-size: 0.7em;
  color: var(--v-error-base);
  text-align: center;
}
.input-container {
  &:not(.uploading) {
    border: 2px dashed var(--v-primary-base);
  }
  &.uploading {
    &:before {
      opacity: 0;
    }
  }

  position: relative;
  min-height: 200px;
  &:hover:not(.uploading) {
    &:before {
      opacity: 0.15;
    }
  }
  &.disabled {
    border-color: rgba(0, 0, 0, 0.5);
    &:before {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  &.dropping {
    &.disabled {
      border-color: var(--v-error-base);
      &:before {
        background-color: var(--v-error-base);
      }
    }

    &:not(.disabled) {
      border-color: var(--v-success-base);
      &:before {
        background-color: var(--v-success-base);
      }
    }
  }
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: var(--v-primary-base);
    opacity: 0.1;
    top: 0;
    left: 0;
    transition: all 0.2s;
  }
  .fileinput {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    margin-bottom: 0px !important;

    cursor: pointer;
    ::v-deep .v-input__control {
      height: 100%;
      .v-input__slot {
        height: 100%;
      }
    }
    ::v-deep .v-file-input__text {
      cursor: pointer !important;
    }
    ::v-deep .v-input__prepend-outer {
      display: none !important;
    }
  }
  .file-upload__instruction {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    width: 100%;
    height: fit-content;
    font-size: 0.8em;
    pointer-events: none;
  }

  .upload-progress__wrap {
    position: absolute;
    height: 50px;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
<script>
import debounce from 'lodash.debounce';

import { uploadPatientFile } from '../utils/file-upload';
import { UPLOAD_ORIGINS } from '../constants/upload';

import { timeout } from '../utils/async';

export default {
  name: 'UploadFile',
  data() {
    return {
      currentUploadIndex: null,
      debounceSetDropping: null,
      file: null,
      filesError: {},
      fileSizeError: [],
      filesSuccess: {},
      filesToUpload: [],
      isDropping: false,
      map: {
        '.pdf': 'application/pdf',
      },
      maxFiles: 3,
      // in bytes
      maxSize: 10000000,
      uploadComplete: false,
      uploading: false,
      uploadProgress: 0,
    };
  },
  props: {
    acceptedFileTypes: {
      type: Array,
      default() {
        return ['.pdf'];
      },
    },
    origin: String,
  },
  computed: {
    dropDisabled() {
      return this.filesToUpload.length >= this.maxFiles;
    },
    fileIconColor() {
      if (this.isDropping) {
        return this.dropDisabled ? 'error' : 'success';
      }
      return this.dropDisabled ? 'grey lighten-1' : 'primary';
    },
  },
  methods: {
    addFile(file) {
      this.fileSizeError = [];
      if (this.dropDisabled) return;
      if (Array.isArray(file)) {
        file.forEach((f) => {
          const isValid = this.checkFile(f);
          if (isValid) this.filesToUpload.push(f);
        });
      } else {
        const isValid = this.checkFile(file);
        if (isValid) this.filesToUpload.push(file);
      }
    },
    addFileDrop(e) {
      this.fileSizeError = [];
      this.isDropping = false;
      if (this.dropDisabled) return;
      this.isDropping = false;
      const droppedFiles = e.dataTransfer?.files;
      if (!droppedFiles) return;
      const [file] = droppedFiles;
      const isFileTypeAllowed = this.checkFileValidType(file);

      if (!isFileTypeAllowed) return;

      const isFileSizeValid = this.checkFileSize(file);

      if (!isFileSizeValid) {
        this.fileSizeError.push(file.name);
        return;
      }

      this.filesToUpload.push(file);
    },
    checkFile(file) {
      const isFileTypeAllowed = this.checkFileValidType(file);
      if (!isFileTypeAllowed) return false;
      const isFileSizeValid = this.checkFileSize(file);

      if (!isFileSizeValid) {
        this.fileSizeError.push(file.name);
        return false;
      }
      return true;
    },
    checkFileSize(file) {
      return file.size <= this.maxSize;
    },
    checkFileValidType(file) {
      const { type } = file;
      return this.acceptedFileTypes.some((t) => this.map[t] === type);
    },
    deleteItem(file) {
      const fileToDeleteIndex = this.filesToUpload.map((f) => f.name).indexOf(file.name);
      this.filesToUpload.splice(fileToDeleteIndex, 1);
    },
    async handleUpload() {
      this.$emit('uploading', true);
      this.uploading = true;
      const progressIncrement = 100 / this.filesToUpload.length;

      await this.filesToUpload.reduce(async (prom, file, index) => {
        await prom;
        this.currentUploadIndex = index;
        try {
          const { id: memberId } = this.$store.getters['patientPortal/currentUser'];
          await uploadPatientFile({
            file,
            memberId,
            origin: UPLOAD_ORIGINS.PATIENT_PORTAL,
          });

          this.$set(this.filesSuccess, index, true);
        } catch (e) {
          this.$set(this.filesError, index, e);
        }
        this.uploadProgress += progressIncrement;

        await timeout(200);
      }, Promise.resolve());
      this.currentUploadIndex = null;
      this.uploadComplete = true;
      this.$emit('uploading', false);
    },
    onButtonClick() {
      if (this.uploadComplete) {
        this.$emit('close');
      } else {
        this.handleUpload();
      }
    },
  },
  created() {
    this.debounceSetDropping = debounce(
      (bool) => {
        this.isDropping = bool;
      },
      10,
      { trailing: true }
    );
  },
  beforeDestroy() {
    this.debounceSetDropping = null;
  },
};
</script>
